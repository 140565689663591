<template>
  <div class="page">
    <Navbar title="项目新增" type="SMP" />
    <van-form @submit="submit" class="form">
      <van-cell-group>
        <van-field name="uploader" label="个人照片">
          <template #input>
            <van-uploader
              v-model="photoImages"
              :after-read="photoRead"
              :before-delete="photoDelete"
              :max-count="1"
            />
            <span class="tips fc-orange"
              >请上传个人照片用于封面显示照片大小小于2M</span
            >
          </template>
        </van-field>
        <van-field
          v-model="photo.description"
          rows="2"
          autosize
          label="照片描述"
          type="textarea"
          maxlength="128"
          placeholder="请输入照片描述"
          show-word-limit
        />
      </van-cell-group>
      <van-row>
        <van-col offset="4" span="16" class="subbtn">
          <van-button
            icon="passed"
            size="small"
            color="#00CC99"
            class="btn"
            native-type="submit"
          >
            提交保存
          </van-button>
        </van-col>
      </van-row>
    </van-form>
    <van-popup :show="loadingShow" style="background-color: transparent"
      ><van-loading size="24px" :color="COLOR" vertical>加载中...</van-loading>
    </van-popup>
  </div>
</template>
<script>
import { Form, CellGroup, Field, Uploader, Loading } from 'vant'
import Navbar from '../../common/Navbar.vue'
export default {
  components: {
    Navbar: Navbar,
    [Form.name]: Form,
    [CellGroup.name]: CellGroup,
    [Field.name]: Field,
    [Uploader.name]: Uploader,
    [Loading.name]: Loading
  },
  data () {
    return {
      id: '',
      personCode: '',
      seq: '',
      photo: { code: '', description: '' },
      photoImages: [],
      loadingShow: false
    }
  },
  mounted () {
    var query = this.$route.query
    this.personCode = query.personCode
    this.seq = query.seq
    this.retrievePhoto()
  },
  methods: {
    async retrievePhoto () {
      var pd = { personCode: this.personCode, key: 'PSN_PTO_ITEM', type: 'DTL', seq: this.seq }
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/retrievePersonAttr', this.$qs.stringify(pd))
      if (res.status === '200') {
        var content = JSON.parse(res.data.content)
        this.photo.code = content.code
        this.photo.description = content.description
        this.retrieveImage()
      }
    },
    async retrieveImage () {
      var code = this.photo.code
      if (code !== '' && code !== null && code !== undefined) {
        var pd = { code: code }
        const { data: res } = await this.$http.post(this.BMS_URL + '/storage/image/retrieveImagePath', this.$qs.stringify(pd))
        if (res.status === '200') {
          this.photoImages.push({ url: this.IMAGE_DOMAIN + '/' + res.data.path })
        }
      }
    },
    async submit () {
      var attrs = [
        { key: 'PSN_PTO_ITEM', content: JSON.stringify(this.photo), type: 'DTL', seq: this.seq }
      ]
      var pd = { personCode: this.personCode, attrs: JSON.stringify(attrs) }
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/editPersonAttr', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.$router.go(-1)
      } else {
        this.$dialog.alert({
          title: '提示信息',
          message: '编辑失败:' + res.msg
        })
      }
    },
    async photoDelete () {
      var pd = { code: this.photo.code }
      const { data: res } = await this.$http.post(this.BMS_URL + '/storage/image/deleteImage', this.$qs.stringify(pd))
      if (res.status === '200') {
        return true
      } else {
        return false
      }
    },
    photoRead (file) {
      this.uploadImage(file.content, file.file.name, '个人相册')
    },
    async uploadImage (content, name, alias) {
      var that = this
      this.loadingShow = true
      var pd = { mode: 'PSN', content: content, name: name, alias: alias, attach: 'photo' }
      const { data: res } = await this.$http.post(this.BMS_URL + '/storage/image/uploadImage', this.$qs.stringify(pd))
      if (res.status === '200') {
        that.photo.code = res.data.code
      }
      this.loadingShow = false
    }
  }
}
</script>
<style lang="less" scoped>
.form {
  padding: 50px 5px;
  .btn {
    margin-top: 10px;
  }
}
</style>
